<template>
  <TicketTable
    v-if="
      this.$route.name === 'Tickets' ||
        this.$route.name === 'UnassignedTickets' ||
        this.$route.name === 'DayTickets' ||
        this.$route.name === 'WeekTickets' ||
        this.$route.name === 'MyTickets'
    "
    :tablePaginatedData="this.pagination().querySet"
    @sorting="$emit('sort', $event)"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    :statuses="statuses"
    :searchStatus="this.searchStatus"
    @pushStatusSearch="$emit('pushStatusSearch', $event)"
    @clearStatusFilter="$emit('clearStatusFilter')"
    :searchPriority="this.searchPriority"
    @pushPrioritySearch="$emit('pushPrioritySearch', $event)"
    @clearPriorityFilter="$emit('clearPriorityFilter')"
    @getTickets="$emit('getTickets')"
  />
  <IncomingEmailsTable
    v-if="this.$route.name.includes('IncomingEmails')"
    :tablePaginatedData="this.pagination().querySet"
    :outTickets="this.outTickets"
    :ticket_id="this.ticket_id"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
  />
  <CustomerFilesList
    v-if="this.$route.name === 'CustomerDetail'"
    :tablePaginatedData="this.pagination().querySet"
  />
  <SftpAccountsTable
    v-if="this.$route.name === 'SftpAccounts'"
    :tablePaginatedData="this.pagination().querySet"
    :nextSftpAccountsAvailable="this.nextSftpAccountsAvailable"
    @openEditForm="(...args) => $emit('openEditForm', ...args)"
    @sorting="$emit('sort', $event)"
  />
  <TicketCategoriesTable
    v-if="this.$route.name === 'TicketCategories'"
    :tablePaginatedData="this.pagination().querySet"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
  />
  <TicketSubCategoriesTable
    :categoryId="this.categoryId"
    v-if="this.$route.name === 'TicketSubCategories'"
    :tablePaginatedData="this.pagination().querySet"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
  />
  <TicketStatusesTable
    v-if="this.$route.name === 'TicketStatuses'"
    :tablePaginatedData="this.pagination().querySet"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
  />
  <HostsTable
    :openHostView="openHostView"
    @openHost="$emit('openHost', $event)"
    v-if="this.$route.name === 'Hosts'"
    :tablePaginatedData="this.pagination().querySet"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    @sorting="$emit('sort', $event)"
  />
  <InfrastructureTable
    :openInfraView="openInfraView"
    @openInfra="$emit('openInfra', $event)"
    v-if="this.$route.name === 'Infrastructures'"
    :tablePaginatedData="this.pagination().querySet"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    @openEditForm="$emit('openEditForm', $event)"
  />
  <CallAnalyser
    v-if="this.$route.name === 'Statistics'"
    :tablePaginatedData="this.pagination().querySet"
    :openAllCalls="openAllCalls"
    :closeAllCalls="closeAllCalls"
    @setCloseAllCallsToFalse="$emit('setCloseAllCallsToFalse')"
  />
  <nav
    v-if="tableData.length"
    class="bg-white px-4 py-3 flex flex-wrap items-center justify-between border-t border-gray-200 sm:px-6 shadow"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        {{ $t("ticketTable.showing") }}
        {{ " " }}

        <span class="font-medium" v-show="!selectedRows">{{
          this.rows * this.page - this.rows + 1
        }}</span>
        <span class="font-medium" v-show="selectedRows">{{
          this.selectedRows * this.page - this.selectedRows + 1
        }}</span>
        {{ " " }}
        {{ $t("ticketTable.to") }}
        {{ " " }}
        <span class="font-medium">{{ getPageLength() }} </span>
        {{ " " }}
        {{ $t("ticketTable.of") }}
        {{ " " }}
        <span class="font-medium">{{ this.tableData.length }}</span>
        {{ " " }}
        {{ $t("ticketTable.results") }}
      </p>
    </div>
    <div class="sm:px-10">
      <select
        @change="setRows($event)"
        id="location"
        name="location"
        class="rounded mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm "
      >
        <option :value="this.rows">{{ $t("ticketTable.selectRows") }}</option>
        <option v-for="numb in this.selectRows" :key="numb" :value="numb"
          >{{ numb }} {{ $t("ticketTable.rows") }}</option
        >
        <option :value="this.tableData.length">{{
          $t("ticketTable.showAll")
        }}</option>
      </select>
    </div>
    <div class="flex-1 flex sm:justify-end pt-3 sm:pt-0">
      <a
        href="javascript:void(0)"
        @click="this.page = 1"
        class="rounded relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>
      </a>
      <a
        href="javascript:void(0)"
        @click="previousPage"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        {{ $t("ticketTable.previous") }}
      </a>

      <a
        href="javascript:void(0)"
        @click="nextPage"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        {{ $t("ticketTable.next") }}
      </a>
      <a
        href="javascript:void(0)"
        @click="this.page = this.pagination().pages"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </a>
    </div>
  </nav>
</template>

<script>
import TicketTable from "../components/tickets/TicketTable.vue";
import IncomingEmailsTable from "./tickets/incomingEmails/IncomingEmailsTable.vue";
import CustomerFilesList from "../components/customers/CustomerFilesList.vue";
import OpenTicketsList from "../components/tickets/OpenTicketsList.vue";
import SftpAccountsTable from "../components/sftp/SftpAccountsTable.vue";
import TicketCategoriesTable from "./configuration/support/TicketCategoriesTable.vue";
import TicketSubCategoriesTable from "./configuration/support/TicketSubCategoriesTable.vue";
import TicketStatusesTable from "./configuration/support/TicketStatusesTable.vue";
import HostsTable from "./hosting/HostsTable.vue";
import InfrastructureTable from "./hosting/InfrastructureTable.vue";
import CallAnalyser from "./PBX/CallAnalyser.vue";

export default {
  name: "TablePagination",
  props: [
    "tableData",
    "preview",
    "previewData",
    "documentHeaderName",
    "previewTableHeader",
    "id",
    "callqueueTable",
    "nextSftpAccountsAvailable",
    "rows",
    "categoryId",
    "statuses",
    "searchStatus",
    "searchPriority",
    "openInfraView",
    "outTickets",
    "ticket_id",
    "openAllCalls",
    "closeAllCalls",
  ],
  components: {
    CallAnalyser,
    TicketTable,
    IncomingEmailsTable,
    CustomerFilesList,
    OpenTicketsList,
    SftpAccountsTable,
    TicketCategoriesTable,
    TicketSubCategoriesTable,
    TicketStatusesTable,
    HostsTable,
    InfrastructureTable,
  },
  data() {
    return {
      page: 1,
      selectedRows: null,
      selectRows: [5, 10, 15, 20, 25, 30],
    };
  },
  methods: {
    changePage(event) {
      this.page = event.target.value;
    },
    nextPage() {
      if (this.page < this.pagination().pages) {
        this.page = parseInt(this.page) + 1;
      } else {
        this.page = this.page;
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page = parseInt(this.page) - 1;
      } else {
        this.page = this.page;
      }
    },
    getPageLength() {
      if (this.page === this.pagination().pages) {
        return this.tableData.length;
      } else {
        if (this.selectedRows) {
          return this.selectedRows * this.page;
        } else {
          return this.rows * this.page;
        }
      }
    },
    setRows(event) {
      this.selectedRows = event.target.value;
    },
    pagination() {
      if (this.selectedRows) {
        let trimStart = (this.page - 1) * parseInt(this.selectedRows);
        let trimEnd = trimStart + parseInt(this.selectedRows);

        let trimmedData = this.tableData.slice(trimStart, trimEnd);

        let pages = Math.ceil(this.tableData.length / this.selectedRows);
        return {
          querySet: trimmedData,
          pages: pages,
        };
      } else {
        let trimStart = (this.page - 1) * parseInt(this.rows);
        let trimEnd = trimStart + parseInt(this.rows);

        let trimmedData = this.tableData.slice(trimStart, trimEnd);

        let pages = Math.ceil(this.tableData.length / this.rows);
        return {
          querySet: trimmedData,
          pages: pages,
        };
      }
    },
  },
};
</script>

<style></style>
