<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-t-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(callInfo, idx) in tablePaginatedData" :key="callInfo.id">
        <a
          @click="setShowDetail(idx)"
          class="block hover:bg-gray-50 cursor-pointer"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium primary_text truncate">
                Call #{{ callInfo.id }}
              </p>
              <div class="ml-2 flex-shrink-0 flex">
                <p
                  :class="[
                    callInfo.isAnswered == 1
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800',
                    'uppercase px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
                  ]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    :class="[
                      callInfo.isAnswered == 1
                        ? 'text-green-800'
                        : 'text-red-800',
                      'h-5 w-5 mr-1',
                    ]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      :d="
                        callInfo.isAnswered == 1
                          ? 'M5 13l4 4L19 7'
                          : 'M6 18L18 6M6 6l12 12'
                      "
                    />
                  </svg>
                  {{
                    callInfo.isAnswered == 1
                      ? $t("chartTable.data.answered")
                      : $t("chartTable.data.unanswered")
                  }}
                </p>
              </div>
            </div>
            <div class="mt-2 sm:flex sm:justify-between">
              <div class="sm:flex">
                <div class="flex items-center text-sm text-gray-500">
                  <UsersIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <ul class="inline-flex">
                    <li
                      v-for="(chain, chainIdx) in callInfo.chain.split(',')"
                      :key="chain"
                      class="flex items-center"
                    >
                      <span
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                      >
                        {{ chain }}
                      </span>
                      <font-awesome-icon
                        icon="chevron-circle-right"
                        class="icon alt px-1"
                        v-show="chainIdx < callInfo.chain.split(',').length - 1"
                      />
                    </li>
                  </ul>
                </div>
                <p
                  class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
                  v-if="callInfo.duration"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-400 flex-shrink-0 mr-1.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  {{ formatTime(callInfo.duration) }}
                </p>
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <div class="inline-block">
                  <p class="capitalize">
                    <font-awesome-icon
                      icon="calendar-check"
                      class="icon alt px-1"
                    />
                    {{ $t("start") }}:
                    {{ " " }}
                    <time :datetime="callInfo.timeStart"
                      >{{ formatDate(callInfo.timeStart)[0] }},
                      {{ formatDate(callInfo.timeStart)[1] }}</time
                    >
                  </p>
                  <p class="capitalize">
                    <font-awesome-icon
                      icon="calendar-times"
                      class="icon alt px-1"
                    />

                    {{ $t("end") }}:
                    {{ " " }}
                    <time :datetime="callInfo.timeEnd">
                      {{ formatDate(callInfo.timeEnd)[0] }},
                      {{ formatDate(callInfo.timeEnd)[1] }}
                    </time>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a>
        <call-analyser-detail-component
          :id="callInfo.code"
          v-if="showIdx.includes(idx)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
} from "@heroicons/vue/solid";

import CallAnalyserDetailComponent from "./CallAnalyserDetailComponent.vue";

export default {
  props: ["tablePaginatedData", "openAllCalls", "closeAllCalls"],
  components: {
    CalendarIcon,
    LocationMarkerIcon,
    UsersIcon,
    CallAnalyserDetailComponent,
  },
  data() {
    return {
      showDetail: false,
      showIdx: [],
    };
  },
  methods: {
    formatTime(time) {
      let formatedTime = "";
      let splitedTime = time.split(":");
      console.log(splitedTime);
      formatedTime =
        splitedTime[0] +
        ":" +
        splitedTime[1] +
        ":" +
        splitedTime[2].slice(0, 5);
      return formatedTime;
    },
    formatDate(date) {
      let split = date.split("-");
      let hour = split[2].slice(2);
      let day = split[2].slice(0, 2);
      let month = split[1];
      let year = split[0];
      let finalDate = day + "/" + month + "/" + year;
      return [finalDate, hour];
    },
    roundNumber(num) {
      return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
    },
    formatChain(chain) {
      let formatedChain = "";
      let splitChain = [];
      splitChain = chain.split(",");
      for (let index = 0; index < splitChain.length; index++) {
        const element = splitChain[index];
        if (index === 0) {
          formatedChain = element;
        } else {
          formatedChain = formatedChain + " -> " + element;
        }
      }
      return formatedChain;
    },
    goToDetail(id) {
      let routeData = this.$router.resolve({
        path: `/analytics/detail/${id}`,
      });
      window.open(routeData.href, "_blank");
    },
    setShowDetail(idx) {
      this.$emit("setCloseAllCallsToFalse");
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (!this.showIdx.includes(idx)) {
        this.showIdx.push(idx);
        // this.showDetail = true;
      } else {
        let findIndex = undefined;
        // this.showDetail = !this.showDetail;
        findIndex = this.showIdx.indexOf(idx);
        this.showIdx.splice(findIndex, 1);
      }
      this.$forceUpdate();
    },
  },
  watch: {
    closeAllCalls: function(val) {
      if (val) {
        this.showIdx.length = 0;
      }
    },
    openAllCalls: function(val) {
      if (val) {
        this.showIdx.length = 0;
        for (let index = 0; index < this.tablePaginatedData.length; index++) {
          this.showIdx.push(index);
        }
      }
    },
    tablePaginatedData: function(val) {
      if (this.openAllCalls) {
        this.showIdx.length = 0;
        for (let index = 0; index < this.tablePaginatedData.length; index++) {
          this.showIdx.push(index);
        }
      }
    },
  },
};
</script>
