<template>
  <td class="pr-3 pl-3">
    <div class="inline-flex">
      <button
        @click="$emit('openEditForm', id)"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1 z-10`
        "
        title="Edit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </button>
      <a
        @click="
          deleteInfrastructure(
            id,
            `Delete Infrastructure`,
            'Do you wish to continue?',
            'Continue',
            'Go back',
            'Infrastructure deleted successfully'
          )
        "
        href="javascript:void(0)"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
        "
        title="Delete"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          /></svg
      ></a>
      <a
        @click="this.$router.push(`/open-ticket/${customerId}/${contactId}`)"
        href="javascript:void(0)"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600`
        "
        title="Ticket"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
          /></svg
      ></a>
    </div>
  </td>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../ConfirmationDialog.vue";
import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import HoverTooltip from "../HoverTooltip.vue";

export default {
  props: ["id", "name", "customerId"],
  components: {
    ConfirmationDialog,
    AskConfirmationDialog,
    HoverTooltip,
  },
  data() {
    return {
      closePutForm: true,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
      contactId: null,
    };
  },
  methods: {
    async getCustomer() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/customers/${this.customerId}`
        );
        this.contactId = res.data.contacts[0].id;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    editTicket() {
      this.$router.push(`/edit-ticket/${this.id}/${this.customerId}`);
    },
    async deleteInfrastructure(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie("API")}/api/v1/infrastructures/${id}`
          )
          .then((res) => {
            if (res) {
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            }
          })
          .catch((error) => {
            this.errorHandling(error);
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", "Something went wrong.");
          });
      }
    },
  },
  mounted() {
    this.getCustomer(this.customerId);
  },
};
</script>

<style></style>
