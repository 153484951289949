<template>
  <body class="flex items-center justify-center">
    <div class="">
      <table
        class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed"
      >
        <thead class="divide-y divide-gray-200 body__table--header__text">
          <tr
            class="body__table--header flex flex-col flex-no wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
          >
            <th
              class="p-3 text-left uppercase text-sm"
              v-for="header in tableHeaders"
              :key="header.name"
            >
              <div
                class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
                @click="
                  this.setSorting(header.sort, header.name),
                    (header.isSort = !header.isSort)
                "
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  v-if="header.sort"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>
            <th class="w-40">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>

        <tbody
          class="flex-1 sm:flex-none divide-y divide-gray-200 body__table--body"
          v-if="tablePaginatedData.length"
        >
          <tr
            v-for="(infra, idx) in this.tablePaginatedData"
            :key="infra.id"
            :class="[
              idx === infraClickedId && openInfraView
                ? 'bg-gray-100'
                : `bg-white`,
              'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
            ]"
          >
            <td
              class=""
              @click="$emit('openInfra', infra.id), (infraClickedId = idx)"
            >
              <div class="p-3 truncate font-medium body__table--body__text">
                {{ infra.customer.name }}
              </div>
            </td>
            <td
              class=""
              @click="$emit('openInfra', infra.id), (infraClickedId = idx)"
            >
              <div class="p-3 truncate font-medium body__table--body__text">
                {{ infra.name }}
              </div>
            </td>
            <td
              class=""
              @click="$emit('openInfra', infra.id), (infraClickedId = idx)"
            >
              <div class="p-3 truncate font-medium body__table--body__text">
                {{ infra.code }}
              </div>
            </td>

            <td class="text-right">
              <UpdateInfrastructure
                :id="infra.id"
                :customerId="infra.customer_id"
                @activeBanner="$emit('activeBanner')"
                @success="$emit('success', $event)"
                @successMsg="$emit('successMsg', $event)"
                @openEditForm="$emit('openEditForm', $event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</template>

<script>
import UpdateInfrastructure from "./UpdateInfrastructure.vue";

export default {
  props: ["tablePaginatedData", "openInfraView"],
  components: {
    UpdateInfrastructure,
  },
  data() {
    return {
      tableHeaders: [
        { name: "infrastructuresTable.client", sort: "client", isSort: false },
        { name: "infrastructuresTable.name", sort: "name", isSort: false },
        { name: "infrastructuresTable.code", sort: "code", isSort: false },
      ],
      infraClickedId: null,
    };
  },
};
</script>

<style></style>
