<template>
  <div class="z-10 p-5">
    <div class="w-full">
      <form
        @submit.prevent="
          this.name && this.id
            ? putStatus(this.id, 'Status updated successfully')
            : postStatus('Status created successfully')
        "
        class="shadow space-y-8 divide-y divide-gray-200 shadow-md form_padding z-10"
        method="POST"
      >
        <div class="space-y-8 ">
          <div>
            <br />
            <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
              <div class="space-y-6 sm:space-y-5">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
                >
                  <label
                    for="customer-account"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      v-model="putForm.name"
                      required=""
                      type="text"
                      name="cti-name"
                      id="cti-name"
                      class="rounded max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <label
            for="country"
            class="block text-sm font-medium text-gray-700 capitalize"
          >
            {{ $t("color") }}
          </label>
          <div
            class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
          >
            <div v-for="(color, idx) in this.colors" :key="color">
              <span
                @click="(putForm.colors = color), (activeIdx = idx)"
                :class="[
                  activeIdx == idx ? 'ring-2 ring-purple-600' : '',
                  `mt-3 inline-flex items-center px-6 py-3 rounded-xl text-xs font-medium ${color} cursor-pointer`,
                ]"
              >
              </span>
            </div>
          </div>
        </div>
        <div class="pt-5 z-100">
          <div class="flex justify-end z-100">
            <a
              @click="$emit('closeForm')"
              href="javascript:void(0)"
              class="z-100 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded z-100 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["name", "id"],
  components: {},
  data() {
    return {
      putForm: {
        name: "",
        colors: "",
      },
      colors: [],
      activeIdx: null,
    };
  },
  methods: {
    async getColors() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/colors`
        );
        this.colors = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async postStatus(msg) {
      let data = {
        name: this.putForm.name,
        color: this.putForm.colors,
      };
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        data,
      };

      axios
        .request(options)
        .then((res) => {
          this.putForm.name = "";
          this.putForm.colors = "";
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
    async putStatus(id, msg) {
      let data = {
        name: this.putForm.name,
        color: this.putForm.colors,
      };
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/statuses/${id}`,
        data,
      };

      axios
        .request(options)
        .then((res) => {
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
  },
  mounted() {
    if (this.name) {
      this.putForm.name = this.name;
    }
    this.getColors();
  },
};
</script>

<style></style>
