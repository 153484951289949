<template>
  <div class="bg-white overflow-hidden shadow">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(category, idx) in tablePaginatedData" :key="category.id">
        <a
          class="block hover:bg-gray-50 cursor-pointer"
          href="javascript:void(0)"
        >
          <div class="flex items-center">
            <div
              class="min-w-0 flex-1 flex items-center"
              @click="editCategory(idx)"
            >
              <div
                class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 px-4 py-4"
              >
                <div>
                  <p
                    class="text-sm font-medium encom_primary_text_dark truncate"
                  >
                    {{ category.name }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="min-w-0 flex-1 flex items-center"
              @click="editCategory(idx)"
            >
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p
                    class="text-sm font-medium encom_primary_text_dark truncate capitalize"
                  >
                    {{ getPriorityById(category.priority_id) }}
                  </p>
                </div>
              </div>
            </div>
            <td class="pr-3 pl-3">
              <div class="inline-flex">
                <button
                  @click="editCategory(idx)"
                  :class="
                    `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
                  "
                >
                  <HoverTooltip text="Edit" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <a
                  @click="
                    deleteCategory(
                      category.id,
                      `Delete Category`,
                      'Do you wish to continue?',
                      'Continue',
                      'Go back',
                      'Category deleted successfully'
                    )
                  "
                  href="javascript:void(0)"
                  :class="
                    `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
                  "
                >
                  <HoverTooltip text="Delete"/>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    /></svg
                ></a>
              </div>
            </td>
            <!-- <div>
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div> -->
          </div>
        </a>
        <div v-if="String(this.showIdx) === String(idx) && this.showEdit">
          <EditTicketSubCategorie
            :categoryId="this.categoryId"
            :id="category.id"
            :name="category.name"
            :priority="category.priority_id"
            @closeForm="this.showEdit = false"
            @activeBanner="$emit('activeBanner')"
            @successMsg="$emit('successMsg', $event)"
            @success="$emit('success', $event), (this.showEdit = false)"
          />
        </div>
      </li>
    </ul>
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../../ConfirmationDialog.vue";
import AskConfirmationDialog from "../../../components/AskConfirmationDialog.vue";
import HoverTooltip from "../../HoverTooltip.vue";
import EditTicketSubCategorie from "../../../components/configuration/support/EditTicketSubCategorie.vue";

import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
} from "@heroicons/vue/solid";

export default {
  props: [
    "tablePaginatedData",
    "backendPagination",
    "to",
    "from",
    "total",
    "lastPage",
    "categoryId",
  ],
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    HoverTooltip,
    ConfirmationDialog,
    AskConfirmationDialog,
    EditTicketSubCategorie,
  },
  data() {
    return {
      showEdit: false,
      showIdx: "",
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
  methods: {
    goToDetail(id, name) {
      this.$router.push(`/support/tickets-categories/${id}/${name}`);
    },
    editCategory(idx) {
      if (this.showIdx !== "" && idx != this.showIdx && this.showEdit) {
        this.showIdx = idx;
        this.showEdit = true;
      } else {
        this.showEdit = !this.showEdit;
        this.showIdx = idx;
      }
    },
    async deleteCategory(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie("API")}/api/v1/ticketSubCategories/${id}`
          )
          .then((res) => {
            if (res) {
              // this.sendMessage(successMsg);
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            }
          })
          .catch((error) => {
            this.errorHandling(error);
          });
      }
    },
    getPriorityById(id) {
      if (id == 1) {
        return "basse";
      }
      if (id == 2) {
        return "normale";
      }
      if (id == 3) {
        return "haute";
      }
      if (id == 4) {
        return "critique";
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {},
};
</script>

<style></style>
