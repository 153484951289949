<template>
  <div class="bg-white overflow-hidden rounded-b-md mt-2">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="ticket in tablePaginatedData" :key="ticket.id">
        <a
          class="block hover:bg-gray-50 cursor-pointer"
          href="javascript:void(0)"
        >
          <div class="flex items-center px-4 py-4">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p
                    @click="
                      goToDetail(
                        ticket.id,
                        ticket.status_id,
                        ticket.ticketable_id
                      )
                    "
                    class="text-sm font-medium encom_primary_text_dark encom_primary_text_dark_hover truncate underline"
                  >
                    {{ ticket.subject }}
                  </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <MailIcon
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span class="truncate">{{ ticket.email }}</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      Created at
                      {{ " " }}
                      <time :datetime="ticket.created_at">{{
                        formatDate(ticket.created_at)
                      }}</time>
                    </p>
                    <p
                      :class="[
                        ticket.status_id == 1
                          ? `encom_ouvert_ticket`
                          : ticket.status_id == 2
                          ? `encom_en_traitement_ticket`
                          : ticket.status_id == 3
                          ? `encom_pink_bg`
                          : `encom_closed_ticket`,
                        'text-white px-2 inline-flex text-xs leading-5 uppercase rounded-full',
                      ]"
                    >
                      {{ this.checkStatus(ticket.status_id) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <UpdateTicket
                :id="ticket.id"
                :open="ticket.open"
                :customerId="ticket.ticketable_id"
                @activeBanner="$emit('activeBanner')"
                @success="$emit('success', $event)"
                @successMsg="$emit('successMsg', $event)"
              />
            </div>
            <!-- <div>
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div> -->
          </div>
        </a>
      </li>
    </ul>
    <BackendPagination
      v-if="this.backendPagination"
      :to="this.to"
      :from="this.from"
      :total="this.total"
      @firstPage="$emit('firstPage')"
      @previousPage="$emit('previousPage')"
      @nextPage="$emit('nextPage')"
      @lastPage="$emit('lastPage')"
    />

    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
  </div>
</template>

<script>
import axios from "axios";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
} from "@heroicons/vue/solid";

import UpdateTicket from "./UpdateTicket.vue";
import BackendPagination from "../BackendPagination.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: [
    "tablePaginatedData",
    "backendPagination",
    "to",
    "from",
    "total",
    "lastPage",
  ],
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    Loading,
    UpdateTicket,
    BackendPagination,
  },
  data() {
    return {
      status: [],
      isLoading: true,
      fullPage: false,
    };
  },
  methods: {
    async getStatus() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/statuses`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.status = res.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
        this.errorHandling(error);
      }
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },
    goToDetail(id, status, ticketableId) {
      let statusName = this.checkStatus(status);
      let routeData = this.$router.resolve({
        path: `/tickets/${id}/${ticketableId}`,
      });
      window.open(routeData.href, "_blank");
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>

<style></style>
