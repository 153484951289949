<template>
  <div class="z-10 p-5">
    <div class="w-full">
      <form
        @submit.prevent="
          this.name && this.id
            ? putCategory(this.id, 'Category updated successfully')
            : postCategory('Category created successfully')
        "
        class="shadow space-y-8 divide-y divide-gray-200 shadow-md form_padding z-10"
        method="POST"
      >
        <div class="space-y-8">
          <div>
            <br />
            <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
              <div class="space-y-6 sm:space-y-5">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
                >
                  <label
                    for="customer-account"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      v-model="putForm.name"
                      required=""
                      type="text"
                      name="cti-name"
                      id="cti-name"
                      class="rounded max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <fieldset class="space-y-5 pl-2">
          <legend class="sr-only">Notifications</legend>
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                v-model="putForm.active"
                id="active"
                aria-describedby="active"
                name="active"
                type="checkbox"
                class=" h-4 w-4 primary_text border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="active" class="font-medium text-gray-700"
                >Active</label
              >
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                v-model="putForm.showable"
                id="showable"
                aria-describedby="showable"
                name="showable"
                type="checkbox"
                class=" h-4 w-4 primary_text border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="showable" class="font-medium text-gray-700"
                >Visible for customers</label
              >
            </div>
          </div>
        </fieldset>

        <div class="pt-5 z-100">
          <div class="flex justify-end z-100">
            <a
              @click="$emit('closeForm')"
              href="javascript:void(0)"
              class="z-100 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded z-100 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["name", "id", "active", "showable"],
  components: {},
  data() {
    return {
      putForm: {
        name: "",
        active: true,
        showable: true,
      },
    };
  },
  methods: {
    async postCategory(msg) {
      let data = {
        name: this.putForm.name,
        active: this.putForm.active,
        showable: this.putForm.showable,
      };
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/ticketCategories`,
        data,
      };

      axios
        .request(options)
        .then((res) => {
          this.putForm.name = "";
          this.putForm.active = true;
          this.putForm.showable = true;
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
    async putCategory(id, msg) {
      let data = {
        name: this.putForm.name,
        active: this.putForm.active,
        showable: this.putForm.showable,
      };
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/ticketCategories/${id}`,
        data,
      };

      axios
        .request(options)
        .then((res) => {
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
  },
  mounted() {
    if (this.name) {
      let activeBoolean = false;
      let showableBoolean = false;
      this.putForm.name = this.name;
      if (this.active == 1) {
        activeBoolean = true;
      }
      if (this.showable == 1) {
        showableBoolean = true;
      }
      this.putForm.active = activeBoolean;
      this.putForm.showable = showableBoolean;
    }
  },
};
</script>

<style></style>
