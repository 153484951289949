<template>
  <div class="lg:flex lg:items-center lg:justify-between m-5">
    <div class="flex-1 min-w-0">
      <h2
        class="text-xl font-medium leading-7 body__header--text sm:text-3xl sm:truncate"
      >
        Next SFTP account available
      </h2>
      <div
        class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
      >
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <ServerIcon
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {{ this.nextSftpAccountsAvailable.host }}
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <UserIcon
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {{ this.nextSftpAccountsAvailable.username }}
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <ShieldCheckIcon
            @click="showPasswordNextSftp = !showPasswordNextSftp"
            :class="[
              showPasswordNextSftp ? 'text-gray-600 ' : 'text-gray-400 ',
              'flex-shrink-0 mr-1.5 h-5 w-5 cursor-pointer',
            ]"
            aria-hidden="true"
          />
          {{
            showPasswordNextSftp
              ? this.nextSftpAccountsAvailable.password
              : "***************"
          }}
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <StatusOnlineIcon
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {{
            this.nextSftpAccountsAvailable.active == "0" ? "Inactive" : "Active"
          }}
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500">
          <DocumentTextIcon
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {{ this.nextSftpAccountsAvailable.file }}
        </div>
      </div>
    </div>
    <div class="mt-5 flex lg:mt-0 lg:ml-4">
      <span class="hidden sm:block">
        <button
          @click="
            $emit(
              'openEditForm',
              this.nextSftpAccountsAvailable.id,
              this.nextSftpAccountsAvailable.host,
              this.nextSftpAccountsAvailable.username
            )
          "
          type="button"
          class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 encom_ring_color"
        >
          <PencilIcon
            class="-ml-1 mr-2 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
          Edit
        </button>
      </span>
    </div>
  </div>
  <body class="flex items-center justify-center">
    <div class="">
      <table
        class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed"
      >
        <thead class="divide-y divide-gray-200 body__table--header__text">
          <tr
            class="body__table--header flex flex-col flex-no wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
          >
            <th
              :class="[
                header.name === 'sftpAccountTable.host' ? 'p-3' : '',
                'text-left uppercase text-sm',
              ]"
              v-for="header in tableHeaders"
              :key="header.name"
            >
              <div
                class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
                @click="
                  this.setSorting(header.sort, header.name),
                    (header.isSort = !header.isSort)
                "
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  v-if="header.sort"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>
            <th class="w-40">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>

        <tbody
          class="flex-1 sm:flex-none divide-y divide-gray-200 body__table--body"
        >
          <tr
            v-for="(sftpAccount, idx) in this.tablePaginatedData"
            :key="sftpAccount.id"
            :class="[
              idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
              'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100',
            ]"
          >
            <td class="p-2">
              <div class="text-sm font-medium body__table--body__text">
                {{ sftpAccount.host }}
              </div>
            </td>
            <td class="py-2 mr-3">
              <div>
                <p class="text-sm body__header--text">
                  {{ sftpAccount.username }}
                </p>
                <p
                  v-if="
                    String(this.showIdx) === String(idx) && this.showPassword
                  "
                  class="mt-2 flex items-center text-xs text-gray-500 break-all"
                >
                  <EyeOffIcon
                    @click="this.setShowPassword(idx)"
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                  />

                  {{ sftpAccount.password }}
                </p>
                <p
                  v-else
                  class="mt-2 flex items-center text-xs text-gray-500 break-all"
                >
                  <EyeIcon
                    @click="this.setShowPassword(idx)"
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                  />

                  ************************
                </p>
              </div>
            </td>
            <td class="p-1 truncate">
              <span
                v-if="sftpAccount.active === 1"
                class="px-2 mr-5 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                {{ $t("sftpAccountTable.active") }}
              </span>
              <span
                v-else
                class="px-2 mr-5 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
              >
                {{ $t("sftpAccountTable.inactive") }}
              </span>
              <span
                v-if="sftpAccount.fileable_type === null"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              >
                {{ $t("sftpAccountTable.available") }}
              </span>
              <span
                v-else
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
              >
                {{ $t("sftpAccountTable.unavailable") }}
              </span>
            </td>
            <td class="p-1 truncate text-sm body__header--text">
              <span v-if="this.language === 'en'">
                <time :datetime="sftpAccount.created_at">{{
                  formatDate(sftpAccount.created_at)
                }}</time>
              </span>
              <span v-if="this.language === 'fr'">
                {{ formatDateFrench(sftpAccount.created_at) }}
              </span>
            </td>
            <td class="p-1 truncate text-sm body__header--text">
              <span v-if="this.language === 'en'">
                {{ formatDate(sftpAccount.updated_at) }}
              </span>
              <span v-if="this.language === 'fr'">
                {{ formatDateFrench(sftpAccount.updated_at) }}
              </span>
            </td>
            <td class="p-1 text-sm body__header--text">
              {{ sftpAccount.file }}
            </td>

            <td class="text-right">
              <EditSftpAccount
                :available="sftpAccount.fileable_type"
                @openForm="
                  $emit(
                    'openEditForm',
                    sftpAccount.id,
                    sftpAccount.host,
                    sftpAccount.username
                  )
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</template>

<script>
import {
  EyeOffIcon,
  EyeIcon,
  ServerIcon,
  StatusOnlineIcon,
  DocumentTextIcon,
  ChevronDownIcon,
  ShieldCheckIcon,
  UserIcon,
  PencilIcon,
} from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { mapGetters } from "vuex";
import EditSftpAccount from "./EditSftpAccount.vue";

export default {
  name: "SftpAccountsTable",
  props: ["tablePaginatedData", "nextSftpAccountsAvailable"],
  components: {
    EyeOffIcon,
    EyeIcon,
    EditSftpAccount,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ServerIcon,
    StatusOnlineIcon,
    ChevronDownIcon,
    ShieldCheckIcon,
    DocumentTextIcon,
    UserIcon,
    PencilIcon,
  },
  data() {
    return {
      activeSorting: "",
      showPassword: false,
      showPasswordNextSftp: false,
      openForm: false,
      editId: "",
      showIdx: "",
      tableHeaders: [
        { name: "sftpAccountTable.host", sort: "host", isSort: false },
        { name: "sftpAccountTable.username", sort: "username", isSort: false },
        { name: "sftpAccountTable.status", sort: "active", isSort: false },
        {
          name: "sftpAccountTable.createdDate",
          sort: "created_at",
          isSort: false,
        },
        {
          name: "sftpAccountTable.updatedDate",
          sort: "updated_at",
          isSort: false,
        },
        { name: "sftpAccountTable.file", sort: "file", isSort: false },
      ],
    };
  },
  methods: {
    setSorting(sort, header) {
      this.$emit("sorting", sort);
      this.activeSorting = header;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " à " +
        dateCreated.getHours() +
        ":" +
        dateCreated.getMinutes();

      return formatedDate;
    },
    setShowPassword(idx) {
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (this.showIdx !== "" && idx != this.showIdx && this.showPassword) {
        this.showIdx = idx;
        this.showPassword = true;
      } else {
        this.showPassword = !this.showPassword;
        this.showIdx = idx;
      }
    },
    setId(id) {
      this.openForm = !this.openForm;
      this.editId = id;
    },
  },
  computed: {
    ...mapGetters(["active", "language"]),
  },
};
</script>

<style>
.hidetext {
  -webkit-text-security: circle;
}
</style>
