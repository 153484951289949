<template>
  <div>
    <td
      class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium inline-flex"
    >
      <button
        v-if="this.available === null"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
        "
        @click="$emit('openForm')"
      >
        <HoverTooltip text="Edit list" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </button>
      <p
        v-else
        class="p-1 shadow-sm border-2 border-black rounded-md tooltip mr-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
      </p>
    </td>
  </div>
  <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import { mapGetters } from "vuex";

import ComfirmationDialog from "../../components/ConfirmationDialog.vue";
import HoverTooltip from "../../components/HoverTooltip.vue";

export default {
  name: "EditSftpAccount",
  props: ["available", "openForm"],
  data() {
    return {};
  },
  components: {
    ComfirmationDialog,
    HoverTooltip,
  },
  methods: {},
  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style></style>
