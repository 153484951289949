<template>
  <div class="flow-root bg-white rounded-lg border-t">
    <ul role="list" class="mx-10 mt-5">
      <li
        v-for="(event, eventIdx) in this.arrayOfParticipants"
        :key="event.action"
      >
        <div class="relative pb-8">
          <span
            v-if="eventIdx !== arrayOfParticipants.length - 1"
            class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex space-x-3">
            <div v-if="!event.data">
              <span
                :class="[
                  event.color,
                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                ]"
              >
                <font-awesome-icon
                  :icon="event.icon"
                  class="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div
              class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4"
              v-if="!event.data && typeof event.source === 'object'"
            >
              <div>
                <p class="text-sm text-gray-500">
                  <span
                    v-for="source in event.source"
                    :key="source"
                    class="capitalize font-medium text-gray-900"
                  >
                    {{ source.type }} {{ source.name }} ({{ source.number }})
                  </span>
                  {{ event.action }}
                  <span
                    v-for="target in event.target"
                    :key="target"
                    class="capitalize font-medium text-gray-900"
                  >
                    {{ target.type }} {{ target.name }} ({{ target.number }})
                  </span>
                </p>
              </div>
              <div
                class="text-right text-sm whitespace-nowrap encom_primary_text_dark"
              >
                <time v-if="event.id == '0'"
                  >start at
                  <span class="text-gray-900 font-medium">
                    {{ event.source[0].answer_time.slice(10, -3) }}
                  </span>
                </time>
                <time v-else-if="event.id == '10' || event.id == '13'"
                  >redirect at
                  <span class="text-gray-900 font-medium">
                    {{ event.source[0].end_time.slice(10, -3) }}
                  </span>
                </time>
                <time v-else-if="event.id == '5' || event.id == '6'">
                  answered at
                  <span class="text-gray-900 font-medium">
                    {{ event.source[0].answer_time.slice(10, -3) }}
                  </span>
                  and finish at
                  <span class="text-gray-900 font-medium">
                    {{ event.source[0].end_time.slice(10, -3) }}
                  </span>
                </time>
                <time v-else-if="event.id == '15'">
                  decline at
                  <span class="text-gray-900 font-medium">
                    {{ event.source[0].end_time.slice(10, -3) }}
                  </span>
                </time>
                <time v-else>
                  initialize at
                  <span class="text-gray-900 font-medium">
                    {{ event.source[0].answer_time.slice(10, -3) }}
                    {{ event.source[0].end_time.slice(10, -3) }}
                  </span>
                </time>
              </div>
            </div>
            <div
              role="list"
              class="mx-10 mt-5 flex justify-between"
              v-if="event.data && event.data.length > 0"
            >
              <li v-for="event in event.data" :key="event.action">
                <div class="pb-8">
                  <div class="flex space-x-3 justify-between">
                    <div>
                      <span
                        :class="[
                          event.color,
                          'h-8 w-8 rounded-full flex items-center justify-center ring-6 ring-white',
                        ]"
                      >
                        <font-awesome-icon
                          :icon="event.icon"
                          class="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div class="flex-1 pt-1.5">
                      <div>
                        <p class="text-sm text-gray-500 break-words">
                          <span
                            v-for="source in event.source"
                            :key="source"
                            class="capitalize font-medium text-gray-900 break-words"
                          >
                            {{ source.type }} {{ source.name }} ({{
                              source.number
                            }})
                          </span>
                          {{ event.action }}
                          <span
                            v-for="target in event.target"
                            :key="target"
                            class="capitalize font-medium text-gray-900 break-words"
                          >
                            {{ target.type }} {{ target.name }} ({{
                              target.number
                            }})
                          </span>
                          <span class="font-medium text-gray-900 break-words">
                            role #{{ event.role }}</span
                          >
                        </p>
                      </div>
                      <div class="text-sm encom_primary_text_dark">
                        <time v-if="event.action === 'start the call'"
                          >start at
                          <span class="text-gray-900 font-medium">
                            {{ event.source[0].answer_time.slice(10, -3) }}
                          </span>
                        </time>
                        <time v-else-if="event.action.includes('redirect')"
                          >redirect at
                          <span class="text-gray-900 font-medium">
                            {{ event.source[0].start_time.slice(10, -3) }}
                          </span>
                        </time>
                        <time
                          v-else-if="
                            event.action.includes('has started the call')
                          "
                        >
                          answered at
                          <span class="text-gray-900 font-medium">
                            {{ event.source[0].answer_time.slice(10, -3) }}
                          </span>
                          and finish at
                          <span class="text-gray-900 font-medium">
                            {{ event.source[0].end_time.slice(10, -3) }}
                          </span>
                        </time>
                        <time v-else-if="event.action.includes('decline')">
                          decline at
                          <span class="text-gray-900 font-medium">
                            {{ event.source[0].end_time.slice(10, -3) }}
                          </span>
                        </time>
                        <time v-else>
                          initialize at
                          <span class="text-gray-900 font-medium">
                            {{ event.source[0].answer_time.slice(10, -3) }}
                            {{ event.source[0].end_time.slice(10, -3) }}
                          </span>
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CallAnalyserDetail",
  props: ["id"],
  components: {},

  data() {
    return {
      arrayOfParticipants: [],
      arrayOfExtensions: [],
      participants: {},
      actionId: {
        id0: "actionId.0",
        id5: "actionId.5",
        id6: "actionId.6",
        id9: "actionId.9",
        id10: "actionId.10",
        id13: "actionId.13",
        id15: "actionId.15",
        id101: "actionId.101",
        id102: "actionId.102",
        id103: "actionId.103",
        id104: "actionId.104",
        id400: "actionId.400",
        id408: "actionId.408",
        id409: "actionId.409",
        id418: "actionId.418",
        id425: "actionId.425",
      },
    };
  },
  methods: {
    async getPBXData() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/pbx3cxCalls/${this.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(res.data);
        this.participants = res.data.participants;
        return res.data.participants;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    getActionId(action_id) {
      if (action_id === 0) {
        return {
          id: action_id,
          action: this.$t(this.actionId.id0),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "1") {
        return {
          id: action_id,
          action: "is initializing the call with success",
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "2") {
        return {
          id: action_id,
          action: "initialize call after audio prompt",
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "5") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id5),
          icon: "user-check",
          color: "bg-green-500",
        };
      } else if (action_id === "6") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id6),
          icon: "user-check",
          color: "bg-green-500",
        };
      } else if (action_id === "9") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id9),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "10") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id10),
          icon: "user-clock",
          color: "bg-blue-500",
        };
      } else if (action_id === "13") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id13),
          icon: "users",
          color: "bg-gray-400",
        };
      } else if (action_id === "15") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id15),
          icon: "user-xmark",
          color: "bg-red-400",
        };
      } else if (action_id === "101") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id101),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "102") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id102),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "103") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id103),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "104") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id104),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "400") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id400),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "408") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id408),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "409") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id409),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "418") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id418),
          icon: "user",
          color: "bg-gray-400",
        };
      } else if (action_id === "425") {
        return {
          id: action_id,
          action: this.$t(this.actionId.id425),
          icon: "user",
          color: "bg-gray-400",
        };
      } else {
        return {
          id: action_id,
          action: "unknown",
          icon: "user",
          color: "bg-gray-400",
        };
      }
    },
    async getDstParticipant() {
      let obj = {};
      let objTree = {};
      let objTreeData = {};
      objTreeData["data"] = [];
      let arr = [];
      let extensionArr = [];
      let extensionSameStartTimeArr = [];
      let participants = await this.getPBXData();
      this.arrayOfParticipants = [];
      let arrayOfPart = [];
      const participantKeys = Object.keys(participants);

      extensionSameStartTimeArr = this.hasSameTimeStamp(participants);

      for (let index = 0; index < participantKeys.length; index++) {
        obj = {};
        const key = participantKeys[index];
        const participant = participants[key];
        this.arrayOfExtensions = [];

        obj["id"] = this.getActionId(participant.action_id).id;
        obj["action"] = this.getActionId(participant.action_id).action;
        obj["icon"] = this.getActionId(participant.action_id).icon;
        obj["color"] = this.getActionId(participant.action_id).color;
        obj["source"] = this.returnDstParticipantInfo(participant);
        obj["dn"] = participant.dn;
        obj["display_name"] = participant.display_name;
        obj["role"] = participant.role;
        obj["start_time"] = participant.start_time;
        if (participant.action_party_id) {
          obj["target"] = this.getTargetParticipant(
            participant.action_party_id
          );
        }

        if (participant.action_id != 1 && participant.action_id != 2) {
          arr.push(obj);
        }
      }

      let duplicatedTimes = [];
      let duplicatedTimesFiltered = [];

      extensionSameStartTimeArr.forEach((el, index) => {
        duplicatedTimes.push(el.start_time);
      });

      duplicatedTimesFiltered = Array.from(new Set(duplicatedTimes));

      let arrayObjWithSameStartTime = this.getObjWithSameStartTime(
        extensionSameStartTimeArr,
        duplicatedTimesFiltered
      );

      this.arrayOfParticipants = this.subtractArrays(
        arr,
        this.arrayOfExtensions
      );
      this.arrayOfParticipants = this.arrayOfParticipants.concat(
        arrayObjWithSameStartTime
      );
      this.arrayOfParticipants = this.sortParticipantsWithTree(
        this.arrayOfParticipants
      );
    },
    hasSameTimeStamp(obj) {
      let arr = [];
      let sameStartTimeArr = [];
      const participantKeys = Object.keys(obj);
      for (let index = 0; index < participantKeys.length; index++) {
        const key = participantKeys[index];
        const participant = obj[key];
        // dn_type = 0 - extension
        // action_id != 1 - remove duplicate participant who initialize the call
        // dn_type != 0 - remove participant who starts the call (first participant of the call)
        if (
          participant.dn_type === "0" &&
          participant.action_id != 1 &&
          participant.action_id != 0
        ) {
          arr.push(participant);
        }
      }

      const lookup = arr.reduce((a, e) => {
        a[e.start_time] = ++a[e.start_time] || 0;
        return a;
      }, {});

      sameStartTimeArr = arr.filter((e) => {
        return lookup[e.start_time];
      });
      return sameStartTimeArr;
    },
    getObjWithSameStartTime(
      extensionSameStartTimeArr,
      duplicatedTimesFiltered
    ) {
      let objTree = {};
      let objTreeData = {};

      let arrayTest2 = [];
      let arrayTest = [];

      if (extensionSameStartTimeArr.length > 1) {
        for (let index = 0; index < extensionSameStartTimeArr.length; index++) {
          const element = extensionSameStartTimeArr[index];
          objTree = {};
          objTreeData = {};
          for (
            let indexTimes = 0;
            indexTimes < duplicatedTimesFiltered.length;
            indexTimes++
          ) {
            objTree = {};
            const elementTimes = duplicatedTimesFiltered[indexTimes];
            if (element.start_time === elementTimes) {
              objTree["action"] = this.getActionId(element.action_id).action;
              objTree["icon"] = this.getActionId(element.action_id).icon;
              objTree["color"] = this.getActionId(element.action_id).color;
              objTree["source"] = this.returnDstParticipantInfo(element);
              objTree["role"] = element.role;
              objTree["dn"] = element.dn;
              objTree["display_name"] = element.display_name;
              objTree["start_time"] = element.start_time;
              objTreeData["start_time"] = element.start_time;
              arrayTest2.push(objTree);
              arrayTest2 = arrayTest2.filter((el) => {
                return el.start_time === elementTimes;
              });
              objTreeData["data"] = arrayTest2;
              arrayTest.splice(indexTimes, 1, objTreeData);
              this.arrayOfExtensions.push(objTree);
            }
          }
        }
      }
      return arrayTest;
    },
    subtractArrays(arrParticipants, arrExtensions) {
      let subArr = arrParticipants;
      let indexArr = [];

      if (arrParticipants.length >= arrExtensions.length) {
        for (let index = 0; index < arrParticipants.length; index++) {
          const element = arrParticipants[index];
          for (let indexExt = 0; indexExt < arrExtensions.length; indexExt++) {
            const elementExt = arrExtensions[indexExt];
            if (element.dn == elementExt.dn) {
              indexArr.push(index);
            }
          }
        }
      } else {
        for (let indexExt = 0; indexExt < arrExtensions.length; indexExt++) {
          const elementExt = arrExtensions[indexExt];
          for (let index = 0; index < arrParticipants.length; index++) {
            const element = arrParticipants[index];
            if (element.dn === elementExt.dn) {
              subArr.splice(index, 1);
            }
          }
        }
      }
      // console.log("indexArr", indexArr);
      for (let index = indexArr.length - 1; index >= 0; index--) {
        subArr.splice(indexArr[index], 1);
      }

      return subArr;
    },
    getTimeStamp(time) {
      let timeStamp = new Date(time).getTime();
      return timeStamp;
    },
    sortParticipants(participants) {
      return participants.sort(function(a, b) {
        if (a.source && b.source) {
          let timeStampA = this.getTimeStamp(a.source[0].start_time);
          let timeStampB = this.getTimeStamp(b.source[0].start_time);
          return timeStampA - timeStampB;
        } else {
          return 1;
        }
      });
    },
    sortParticipantsWithTree(participants) {
      return participants.sort(function(a, b) {
        if (a.start_time && b.start_time) {
          let timeStampA = new Date(a.start_time).getTime();
          let timeStampB = new Date(b.start_time).getTime();
          return timeStampA - timeStampB;
        } else {
          return 1;
        }
      });
    },
    getTargetParticipant(partyId) {
      // let participants = await this.getPBXData();
      const participantKeys = Object.keys(this.participants);
      for (let index = 0; index < participantKeys.length; index++) {
        const key = participantKeys[index];
        const participant = this.participants[key];
        if (participant.id == partyId) {
          return this.returnDstParticipantInfo(participant);
        }
      }
    },
    returnDstParticipantInfo(participant) {
      if (participant.dn_type === "0") {
        return [
          {
            type: "extension",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "1") {
        return [
          {
            type: "external number",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "4") {
        return [
          {
            type: "callqueue",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "5") {
        return [
          {
            type: "voicemail",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "6") {
        return [
          {
            type: "IVR",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "12") {
        return [
          {
            type: "unknown number",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "13") {
        return [
          {
            type: "external no response",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else if (participant.dn_type === "14") {
        return [
          {
            type: "call flow",
            name: participant.display_name,
            number: participant.caller_number,
            answer_time: participant.answer_time,
            end_time: participant.end_time,
            start_time: participant.start_time,
          },
        ];
      } else {
        return "unknown";
      }
    },
  },
  mounted() {
    this.getDstParticipant();
  },
};
</script>
