<template>
  <div class="bg-white overflow-hidden rounded-b-md mt-2">
    <ul role="list" class="divide-y divide-gray-200">
      <li
        v-for="application in tablePaginatedData"
        :key="application.applicant.email"
      >
        <a :href="application.href" class="block hover:bg-gray-50">
          <div class="flex items-center px-4 py-4">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p class="text-sm font-medium text-indigo-600 truncate">
                    {{ application.applicant.name }}
                  </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <MailIcon
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span class="truncate">{{
                      application.applicant.email
                    }}</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      Applied on
                      {{ " " }}
                      <time :datetime="application.date">{{
                        application.dateFull
                      }}</time>
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <CheckCircleIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                      {{ application.stage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
} from "@heroicons/vue/solid";

export default {
  props: ["tablePaginatedData"],
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
  },
};
</script>

<style></style>
