<template>
  <div class="z-10 p-5">
    <div class="w-full">
      <form
        @submit.prevent="
          this.name && this.id
            ? putCategory(this.id, 'Category updated successfully')
            : postCategory('Category created successfully')
        "
        class="shadow space-y-8 divide-y shadow-md form_padding z-10"
        method="POST"
      >
        <div class="space-y-8">
          <div>
            <br />
            <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
              <div class="space-y-6 sm:space-y-5">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
                >
                  <label
                    for="customer-account"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      v-model="putForm.name"
                      required=""
                      type="text"
                      name="cti-name"
                      id="cti-name"
                      class="rounded max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <label
            for="country"
            class="block text-sm font-medium text-gray-700 capitalize"
          >
            {{ $t("ticket.priority") }}
          </label>
          <select
            v-model="putForm.priority_id"
            id="country"
            name="country"
            required=""
            autocomplete="country"
            class="mt-1 block w-2/3 rounded pl-3 py-2 text-base border-gray-300 sm:text-sm"
          >
            <option value="">Select Priority</option>
            <option
              v-for="priority in priorities"
              :key="priority.name"
              :value="priority.value"
              ><span class="capitalize">{{ priority.name }}</span></option
            >
          </select>
        </div>
        <div class="pt-5 z-100">
          <div class="flex justify-end z-100">
            <a
              @click="$emit('closeForm')"
              href="javascript:void(0)"
              class="z-100 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded z-100 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const priorities = [
  { name: "Low", value: 1 },
  { name: "Medium", value: 2 },
  { name: "High", value: 3 },
  { name: "Critical", value: 4 },
];

export default {
  props: ["name", "id", "categoryId", "priority"],
  components: {},
  data() {
    return {
      putForm: {
        ticket_category_id: 0,
        name: "",
        active: true,
        priority_id: "",
        support_team_id: 0,
      },
      priorities,
    };
  },
  methods: {
    async postCategory(msg) {
      let data = {
        ticket_category_id: this.categoryId,
        name: this.putForm.name,
        active: this.putForm.active,
        priority_id: this.putForm.priority_id,
        support_team_id: this.putForm.support_team_id,
      };
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/ticketSubCategories`,
        data,
      };

      axios
        .request(options)
        .then((res) => {
          this.putForm.name = "";
          this.putForm.priority_id = "";
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
    async putCategory(id, msg) {
      let data = {
        ticket_category_id: this.categoryId,
        name: this.putForm.name,
        active: this.putForm.active,
        priority_id: this.putForm.priority_id,
        support_team_id: this.putForm.support_team_id,
      };
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie(
          "API"
        )}/api/v1/ticketSubCategories/${id}`,
        data,
      };

      axios
        .request(options)
        .then((res) => {
          console.log(res);
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
  },
  mounted() {
    if (this.name && this.priority) {
      this.putForm.name = this.name;
      this.putForm.priority_id = this.priority;
    }
  },
};
</script>

<style></style>
